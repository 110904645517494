<template>
  <v-card class="pr-5 pl-10" elevation="0">
    <v-breadcrumbs class="ml-10 ma-0 pa-0 mb-5" :items="links" divider=">" large>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
          <p class="caption ma-0" style="color: #8739eb; text-decoration: none">
            {{ item.text }}
          </p>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-card-title class="ml-8 ma-0 pa-0">
      <div class="d-flex">
        <v-btn icon @click="close"><v-icon small>mdi-arrow-left</v-icon></v-btn>
        <div class="d-flex flex-column align-start mt-1">
          <p class="text-body-1 ma-0 font-weight-bold">Nueva vacante</p>
        </div>
      </div>
    </v-card-title>
    <v-card class="ml-8 mt-5" elevation="0">
      <v-divider></v-divider>
      <v-card flat class="d-flex flex-column align-start mt-4 mb-16" elevation="0">
        <p class="text-body-1 ma-0 font-weight-bold ml-4 mb-5">
          Datos generales de la oferta
        </p>
        <v-divider></v-divider>
        <v-card class="pb-5 pa-4" elevation="0" width="100%">
          <v-form v-model="valid" ref="formCreateVacancy" lazy-validation>
            <v-container fluid grid-list-md>
              <v-layout row wrap d-flex justify-start>
                <v-flex d-flex flex-column align-start xs12 sm12 md6>
                  <v-card class="cont-nv" elevation="0" width="95%" color="white">
                    <v-text-field   :counter="20" color="#6200EE" class="input-nv" style="padding: 3px; height: 30px"
                      v-model="newVacancy.vacancy_name" :rules="[(v) => !!(v && v.length <= 20) ||
                        'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-briefcase-outline
                        </v-icon>
                        <span class="caption ml-2">Nombre de la vacante</span>
                      </template>
                    </v-text-field>
                    <!-- <div class="d-flex ms-4" style="color: rgba(0, 0, 0, 0.6);font-size: 12px;">Máximo 20 caracteres</div> -->
                    <v-textarea no-resize rows="4" :counter="3950" color="#6200EE" class="input-nv2" style="margin-top:40px ;height: 40px"
                      v-model="newVacancy.vacancy_description" :rules="[(v) => !!(v && v.length <= 3950) || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-card-text-outline
                        </v-icon>
                        <span class="caption ml-2">Descripción de la Vacante</span>
                      </template>
                    </v-textarea>
                    <!-- <div class="d-flex ms-4" style="color: rgba(0, 0, 0, 0.6);font-size: 12px;">Máximo 3950 caracteres</div> -->
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:120px ;height: 40px" type="number"
                      v-model="newVacancy.vacancy_job_hours" :rules="[(v) => !!v || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-briefcase-clock-outline
                        </v-icon>
                        <span class="caption ml-2">Jornada laboral (horas)</span>
                      </template>
                    </v-text-field>

                    <v-text-field color="#6200EE" class="input-nv" type="number" style="margin-top:40px ;height: 40px"
                      v-model="newVacancy.number_of_available_vacancies"
                      :rules="[(v) => !!v || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small> mdi-numeric </v-icon>
                        <span class="caption ml-2">Vacantes disponibles</span>
                      </template>
                    </v-text-field>

                    <v-text-field color="#6200EE" class="input-nv" v-model="newVacancy.vacancy_company_area"
                      style="margin-top:40px ;height: 40px" :rules="[(v) => !!v || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-briefcase-eye-outline
                        </v-icon>
                        <span class="caption ml-2">Área de la compañia</span>
                      </template>
                    </v-text-field>

                    <v-container class="ma-0 pa-0" fluid grid-list-md>
                      <v-layout row wrap d-flex justify-start>
                        <v-flex d-flex justify-start xs12 sm12 md6>
                          <v-menu :close-on-content-click="false" max-width="290" v-model="menuPublicationDate">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :value="computedDateFormattedDatefnsStart" readonly v-bind="attrs" v-on="on"
                                color="#8739EB" style="padding: 3px; height: 40px" @click:clear="vacancy_starts_at = null"
                                class="caption" v-model="newVacancy.vacancy_starts_at" :rules="[
                                  (v) => !!v || 'Este campo es requerido',
                                ]">
                                <template v-slot:label class="caption">
                                  <v-icon class="icon-input" small>
                                    mdi-calendar-start
                                  </v-icon>
                                  <span class="caption ml-2">Fecha de publicación</span>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker locale="es" color="#8739EB" v-model="newVacancy.vacancy_starts_at"
                              @change="menuPublicationDate = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex d-flex justify-start xs12 sm12 md6>
                          <v-menu v-model="menuExpirationDate" :close-on-content-click="false" max-width="290">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field :value="computedDateFormattedDatefnsEnd" readonly v-bind="attrs" v-on="on"
                                color="#8739EB" style="padding: 3px; height: 40px"
                                @click:clear="vacancy_expires_at = null" class="caption"
                                v-model="newVacancy.vacancy_expires_at" :rules="[
                                  (v) => !!v || 'Este campo es requerido',
                                ]">
                                <template v-slot:label class="caption">
                                  <v-icon class="icon-input" small>
                                    mdi-calendar-end
                                  </v-icon>
                                  <span class="caption ml-2">Fecha de expiración</span>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker locale="es" color="#8739EB" v-model="newVacancy.vacancy_expires_at"
                              @change="menuExpirationDate = false"></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-select v-if="zonesStateLoaded === true" color="#6200EE" class="input-nv"
                      v-model="newVacancy.company_zone_id" :items="getAllZonesToSelect" menu-props="auto"
                      style="padding: 3px; height: 30px" single-line clearable
                      :rules="[(v) => !!v || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-map-marker-outline
                        </v-icon>
                        <span class="caption ml-2">Zona</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <p class="caption">{{ item.text }}</p>
                          <v-divider></v-divider>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </v-card>
                  <p class="text-body-1 d-flex flex-column align-start ma-0 font-weight-bold mt-5 mb-5">
                    Horario de entrevista
                  </p>
                  <v-card class="pa-0 d-flex justify-start" elevation="0" color="white" width="95%">
                    <v-dialog max-width="600px" persistent v-model="dialogSelectedHours">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn-toggle v-model="weekdayAppointmentsSchedule">
                          <v-btn value="Mon" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize" v-bind="attrs"
                            v-on="on" style="border-radius: 25px" @click="getHoursIfExist('Mon')">Lun</v-btn>
                          <v-btn value="Tue" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize" v-bind="attrs"
                            v-on="on" style="border-radius: 25px" @click="getHoursIfExist('Tue')">Mar</v-btn>
                          <v-btn value="Wed" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize" v-bind="attrs"
                            v-on="on" style="border-radius: 25px" @click="getHoursIfExist('Wed')">Mie</v-btn>
                          <v-btn value="Thu" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize" v-bind="attrs"
                            v-on="on" style="border-radius: 25px" @click="getHoursIfExist('Thu')">Jue</v-btn>
                          <v-btn value="Fri" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize" v-bind="attrs"
                            v-on="on" style="border-radius: 25px" @click="getHoursIfExist('Fri')">Vie</v-btn>
                          <v-btn value="Sat" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize" v-bind="attrs"
                            v-on="on" style="border-radius: 25px" @click="getHoursIfExist('Sat')">Sab</v-btn>
                          <v-btn value="Sun" color="#6200EE" dark class="ml-2 px-1 caption text-capitalize" v-bind="attrs"
                            v-on="on" style="border-radius: 25px" @click="getHoursIfExist('Sun')">Dom</v-btn>
                        </v-btn-toggle>
                      </template>
                      <v-card>
                        <v-card-title class="d-flex justify-space-between">
                          <span class="text-h5">Horario disponible para citas</span>
                          <v-btn icon @click="(dialogSelectedHours = false), resetHour()"><v-icon
                              color="#6200EE">mdi-close-circle-outline</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                          <div class="text-caption text-justify">
                            Para añadir un horario hay que darle al botón de '+'
                            y cuando demos en Guardar, guardaremos todos los
                            horarios que ya agregamos.
                          </div>
                        </v-card-text>
                        <v-container class="mt-4">
                          <v-form ref="formSelectHour" v-model="valid" lazy-validation class="d-flex flex-column">
                            <v-row>
                              <v-col cols="5">
                                <v-menu :nudge-right="40" transition="scale-transition" offset-y :return-value.sync="time"
                                  :close-on-content-click="false" v-model="menuStartHour" ref="menu">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly v-bind="attrs" v-on="on" color="#8739EB" class="caption"
                                      v-model="startHourPicker" :rules="[
                                        (v) => !!v || 'Este campo es requerido',
                                      ]">
                                      <template v-slot:label class="caption">
                                        <v-icon class="icon-input" small>
                                          mdi-calendar-start
                                        </v-icon>
                                        <span class="caption ml-2">Hora de inicio</span>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-time-picker v-model="startHourPicker" :max="endHourPicker" format="24hr"
                                    color="#6200EE" @click:minute="$refs.menu.save(time)"
                                    :landscape="$vuetify.breakpoint.smAndUp" header-color="#A4A1FB"></v-time-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="5">
                                <v-menu v-model="menuEndHour" ref="menu2" :nudge-right="40" transition="scale-transition"
                                  offset-y :return-value.sync="time" :close-on-content-click="false">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field readonly v-bind="attrs" v-on="on" color="#8739EB" class="caption"
                                      v-model="endHourPicker" :rules="[
                                        (v) => !!v || 'Este campo es requerido',
                                      ]">
                                      <template v-slot:label class="caption">
                                        <v-icon class="icon-input" small>
                                          mdi-calendar-end
                                        </v-icon>
                                        <span class="caption ml-2">Hora de término</span>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-time-picker v-model="endHourPicker" :min="startHourPicker" format="24hr"
                                    @click:minute="$refs.menu2.save(time)" color="#6200EE"
                                    :landscape="$vuetify.breakpoint.smAndUp" header-color="#A4A1FB"></v-time-picker>
                                </v-menu>
                              </v-col>
                              <!-- botones -->
                              <v-col cols="2" class="d-flex align-center justify-center">
                                <v-btn :disabled="!valid" ref="hour" text class="ma-0" color="#6200EE" fab x-small dark
                                  @click="addHourRange()">
                                  <v-icon small>mdi-plus-circle</v-icon>
                                </v-btn>
                              </v-col>
                              <!-- <v-progress-circular color="#A4A1FB" size="20" width="2" indeterminate></v-progress-circular> -->
                            </v-row>
                            <!-- show -->
                            <v-row dense no-gutters class="mt-5" v-for="(hourRange, index) in selectedHourRanges"
                              :key="index">
                              <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                              <v-chip small close @click:close="remove(index)" v-if="chip" color="#6200EE"
                                class="white--text caption mr-3 mb-1">{{ hourRange.start_hour }} -
                                {{ hourRange.end_hour }}</v-chip>
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-4">
                            <v-spacer>
                              <div class="text-caption text-justify">
                                Recuerda crear varios bloques de horarios en un día.
                              </div>
                            </v-spacer>
                            <v-btn :disabled="!selectHour" color="#6200EE" text @click="saveHoursSchedule()">
                              Listo
                            </v-btn>
                          </v-card-actions>
                        </v-container>
                      </v-card>
                    </v-dialog>
                  </v-card>
                </v-flex>
                <v-flex d-flex flex-column align-start xs12 sm12 md6>
                  <v-card class="cont-nv" width="95%" elevation="0" color="white">
                    <v-text-field color="#6200EE" class="input-nv" style="height: 40px"
                      v-model="newVacancy.vacancy_job_shift_type" :rules="[(v) => !!v || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon small class="icon-input">
                          mdi-clock-outline
                        </v-icon>
                        <span class="caption ml-2">Tipo de horario</span>
                      </template>
                    </v-text-field>
                    <v-select color="#6200EE" class="input-nv caption"
                      v-model="newVacancy.vacancy_minimum_level_of_education_required" :items="school"
                      :rules="[(v) => !!v || 'Este campo es requerido']" menu-props="auto" label="Estudios" single-line
                      clearable>
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-school-outline
                        </v-icon>
                        <span class="caption ml-2">Estudios mínimos requeridos</span>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <p class="caption">{{ item.text }}</p>
                          <v-divider></v-divider>
                        </v-list-item-content>
                      </template>
                    </v-select>
                    <v-text-field color="#6200EE" class="input-nv" v-model="newVacancy.vacancy_country"
                      style="margin-top:40px ;height: 40px" :rules="[(v) => !!v || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small> mdi-earth </v-icon>
                        <span class="caption ml-2">País</span>
                      </template>
                    </v-text-field>
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px"
                      v-model="newVacancy.vacancy_city" :rules="[(v) => !!v || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-city-variant-outline
                        </v-icon>
                        <span class="caption ml-2">Ciudad</span>
                      </template>
                    </v-text-field>
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px"
                      v-model="newVacancy.vacancy_zip_code" :rules="[(v) => !!v || 'Este campo es requerido']">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small> mdi-numeric </v-icon>
                        <span class="caption ml-2">CP</span>
                      </template>
                    </v-text-field>
                  </v-card>
                  <p class="text-body-1 ma-0 font-weight-bold mt-5 mb-5">
                    Dirección de la entrevista
                  </p>
                  <v-card class="cont-nv" elevation="0" width="95%" color="white">
                    <v-text-field color="#6200EE" class="input-nv" style="height: 40px" v-model="
                      newVacancy.interview_address.physical_interview_address
                        .interview_vacancy_street
                    ">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-map-marker-outline
                        </v-icon>
                        <span class="caption ml-2">Calle</span>
                      </template>
                    </v-text-field>
                    <v-container class="ma-0 pa-0" fluid grid-list-md>
                      <v-layout row wrap d-flex justify-start>
                        <v-flex d-flex justify-start xs12 sm12 md6>
                          <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px" v-model="
                            newVacancy.interview_address
                              .physical_interview_address
                              .interview_vacancy_street_number
                          ">
                            <template v-slot:label class="caption">
                              <v-icon class="icon-input" small>
                                mdi-numeric
                              </v-icon>
                              <span class="caption ml-2">Número</span>
                            </template>
                          </v-text-field>
                        </v-flex>
                        <v-flex d-flex justify-start xs12 sm12 md6>
                          <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px" v-model="
                            newVacancy.interview_address
                              .physical_interview_address
                              .interview_vacancy_interior_number
                          ">
                            <template v-slot:label class="caption">
                              <v-icon class="icon-input" small>
                                mdi-numeric
                              </v-icon>
                              <span class="caption ml-2">Número interior</span>
                            </template>
                          </v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px" v-model="
                      newVacancy.interview_address.physical_interview_address
                        .interview_vacancy_neighborhood
                    ">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-map-marker-outline
                        </v-icon>
                        <span class="caption ml-2">Colonia</span>
                      </template>
                    </v-text-field>
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px" v-model="
                      newVacancy.interview_address.physical_interview_address
                        .interview_vacancy_country
                    ">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small> mdi-earth </v-icon>
                        <span class="caption ml-2">País</span>
                      </template>
                    </v-text-field>
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px" v-model="
                      newVacancy.interview_address.physical_interview_address
                        .interview_vacancy_city
                    ">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-city-variant-outline
                        </v-icon>
                        <span class="caption ml-2">Ciudad</span>
                      </template>
                    </v-text-field>
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px" v-model="
                      newVacancy.interview_address.physical_interview_address
                        .interview_vacancy_zip_code
                    ">

                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small> mdi-numeric </v-icon>
                        <span class="caption ml-2">CP</span>
                      </template>
                    </v-text-field>
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px" v-model="
                      newVacancy.interview_address.remote_interview_url
                    ">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-link
                        </v-icon>
                        <span class="caption ml-2">Link dirección de entrevista</span>
                      </template>
                    </v-text-field>
                    <v-text-field color="#6200EE" class="input-nv" style="margin-top:40px ;height: 40px" v-model="
                      newVacancy.interview_address.contact_phone_number
                    ">
                      <template v-slot:label class="caption">
                        <v-icon class="icon-input" small>
                          mdi-phone
                        </v-icon>
                        <span class="caption ml-2">Teléfono de contacto</span>
                      </template>
                    </v-text-field>
                  </v-card>
                  <v-card-actions style="width: 95%" class="mt-5 ma-0 pa-0 d-flex justify-end">
                    <v-btn rounded small elevation="0" color="#E3E9EF9E" style="color: #a4a5ad"
                      class="pl-8 pr-8 text-capitalize" @click="close">Cancelar</v-btn>
                    <v-btn :disabled="!valid" rounded small elevation="0" color="#43425D" style="color: #fff"
                      class="pl-8 pr-8 mr-5 text-capitalize" @click="createVacancy({ newVacancy })">Crear
                      <div v-if="vacanciesStateLoading === true">
                        <transition name="modal-fade">
                          <div class="modal-backdrop">
                            <div class="modal" role="dialog" area-labelledby="modalTitle" aria-describedby="modalDesc">
                              <v-col cols="10">
                                <h4 class="mb-4 font-weight-regular">
                                  espere un momento...
                                </h4>
                                <v-progress-linear color="#6E80F8" indeterminate rounded height="6"></v-progress-linear>
                                <!-- <v-progress-circular
                                    color="white"
                                    size="40"
                                    width="6"
                                    indeterminate
                                  ></v-progress-circular> -->
                              </v-col>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </v-btn>

                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card>
      </v-card>
    </v-card>
    <v-snackbar v-model="alert" timeout="3000">
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="#6200EE" text v-bind="attrs" @click="alert = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
</v-card>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import ReturnPage from "../../mixins/ReturnPage";
import ENUM from "@/store/enums";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  mixins: [ReturnPage],
  data: () => ({
    time: null,
    valid: false,
    selectHour: false,
    chip: true,
    newVacancy: {
      vacancy_name: "",
      vacancy_description: "",
      vacancy_job_hours: "",
      number_of_available_vacancies: "",
      vacancy_company_area: "",
      actual_date: "",
      vacancy_starts_at: "",
      vacancy_expires_at: "",
      vacancy_job_shift_type: "",
      vacancy_minimum_level_of_education_required: "",
      vacancy_country: "",
      vacancy_city: "",
      vacancy_zip_code: "",
      vacancy_appointments_info: {
        max_occupancy: "50",
      },
      interview_address: {
        physical_interview_address: {
          interview_vacancy_street: "",
          interview_vacancy_street_number: "",
          interview_vacancy_interior_number: "",
          interview_vacancy_neighborhood: "",
          interview_vacancy_country: "",
          interview_vacancy_city: "",
          interview_vacancy_zip_code: "",
        },
        remote_interview_url: "",
        contact_phone_number: ""
      },
      vacancy_maximum_candidates: "15",
      vacancy_schedule: {
        Mon: [],
        Tue: [],
        Wed: [],
        Thu: [],
        Fri: [],
        Sat: [],
        Sun: [],
      },
      vacancy_scheduled_appointments: ["Fri Apr 01 2022_08:00-10:00"],
    },
    vacancy_starts_at: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    vacancy_expires_at: format(
      parseISO(new Date().toISOString()),
      "yyyy-MM-dd"
    ),
    menuPublicationDate: false,
    menuExpirationDate: false,
    weekdayAppointmentsSchedule: null,
    dialogSelectedHours: false,
    menuStartHour: false,
    menuEndHour: false,
    startHourPicker: "",
    endHourPicker: "",
    selectedHourRanges: [],
    alert: false,
    alertText: "",
    school: [
      { value: 11, text: "Sin estudios" },
      { value: 1, text: "Primaria Trunca" },
      { value: 2, text: "Primaria terminada" },
      { value: 3, text: "Secundaria trunca" },
      { value: 4, text: "Secundaria terminada" },
      { value: 5, text: "Preparatoria trunca" },
      { value: 6, text: "Preparatoria terminada" },
      { value: 7, text: "Universidad trunca" },
      { value: 8, text: "Universidad terminada" },
      { value: 9, text: "Maestria trunca" },
      { value: 10, text: "Maestria terminada" },
    ],
    links: [
      {
        text: "Vacantes",
        disabled: false,
        href: "/vacancies",
      },
      {
        text: "Nueva vacante",
        disabled: true,
      },
    ],
    href: [
      {
        text: "vacantes",
        disabled: false,
        href: "/vacancies",
        color: "#8739EB",
      },
      {
        text: "Nueva vacante",
        disabled: false,
      },
    ],
  }),
  computed: {
    ...mapGetters([
      "getAllZonesToSelect",
      "getAllZones",
      "getCreateVacancyAttempt",
    ]),
    ...mapState({
      zonesState: (state) => state.zones.status,
      vacanciesState: (state) => state.vacancies.status,
    }),
    computedDateFormattedDatefnsStart() {
      return this.vacancy_starts_at
        ? format(parseISO(this.vacancy_starts_at), "do MMM yyyy")
        : "";
    },
    computedDateFormattedDatefnsEnd() {
      return this.vacancy_expires_at
        ? format(parseISO(this.vacancy_expires_at), "do MMM yyyy")
        : "";
    },
    zonesStateLoaded() {
      return this.zonesState === ENUM.LOADED;
    },
    vacanciesStateLoading() {
      return this.vacanciesState === ENUM.LOADING;
    },
  },
  methods: {
    ...mapActions(["createVacanciesById", "requestsAllZones"]),
    formatDate(date) {
      const dateArray = date.substr(date.indexOf(" ") + 1).split(" ");
      return dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1];
    },
    async getHoursIfExist(dayKey) {
      this.selectedHourRanges = this.newVacancy.vacancy_schedule[dayKey];
    },
    remove(index) {
      let idx = this.selectedHourRanges.indexOf(index);
      this.selectedHourRanges.splice(idx, 1);
      this.selectedHourRanges = [...this.selectedHourRanges];
    },
    addHourRange() {
      const isValid = this.$refs.formSelectHour.validate();
      if (isValid) {
        if (this.startHourPicker === "" || this.endHourPicker === "") {
          this.alertText =
            "Debes llenar ambos campos para añadir el rango de horas";
          this.alert = true;
        } else {
          const newRange = {
            start_hour: this.startHourPicker,
            end_hour: this.endHourPicker,
          };
          this.selectedHourRanges.push(newRange);
          this.selectHour = true;
          this.resetHour();
        }
      } else {
        this.alertText =
          "Debes llenar ambos campos para añadir el rango de horas";
        this.alert = true;
      }
    },
    saveHoursSchedule() {
      if ((this.selectHour = true)) {
        this.$store.commit("SAVE_DAY", this.weekdayAppointmentsSchedule);
        this.newVacancy.vacancy_schedule[this.weekdayAppointmentsSchedule] =
          this.selectedHourRanges;
        this.dialogSelectedHours = false;
        this.resetHour();
      } else {
        this.alertText = "Selecciona un horario";
        this.alert = true;
      }
    },
    async createVacancy(newVacancyPayload) {
   
      const isValid = this.$refs.formCreateVacancy.validate();
      if (isValid) {
        if (newVacancyPayload.newVacancy.vacancy_minimum_level_of_education_required === 11) {
          newVacancyPayload.newVacancy.vacancy_minimum_level_of_education_required = 0
        }
        this.createVacanciesById(newVacancyPayload).then(() => {
          if (this.getCreateVacancyAttempt === 200) {
            this.reset();
            this.$router.push({ path: "/vacancies" });
            setTimeout(() => this.$store.commit("ALERT_OK", false), 3000);
          } else {
            this.alertText = "Algo salió mal, vuelve a intentar crear la vacante más tarde";
            this.alert = true;
          }
        });
      }
    },
    reset() {
      this.$refs.formCreateVacancy.reset();
    },
    resetHour() {
      this.$refs.formSelectHour.reset();
    },
  },
  mounted() {
    this.requestsAllZones();
  },
  watch: {
    dialogSelectedHours(newValue, old) {
      if (!newValue) {
        this.startHourPicker = "";
        this.endHourPicker = "";
      }
    },
  },
};
</script>

<style scoped>
/* .v-input__control{
  display: flex;
  flex-direction: row !important;
  border: 1px solid red;
} */
.icon-input {
  color: #6200ee;
  vertical-align: middle;
  padding-left: 0.8rem;
}

.input-nv {
  width: 100%;
  margin: 0;
  padding: 5px;
  background-color: #fff;
  margin-bottom: 1rem;
  font-size: 12px;
  height: 30px;
  /* border: 1px solid red; */
}

.input-nv2 {
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 3px;
  background-color: #fff;
  margin-bottom: 1rem;
  font-size: 12px;
}

.cont-nv {
  padding: 1rem !important;
  background-color: #f2f3f5;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 25vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background: transparent;
  box-shadow: 0;
  overflow: hidden;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

h4 {
  color: #fff;
  letter-spacing: 2px;
}
</style>
